<template>
    <div class="row justify-content-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Create Partner</h1>
            <hr>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                    <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                        <form @submit.prevent="postPartner" class="row p-2">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="row p-auto">
                                    <div class="col-lg-12 mt-4">
                                        <div class="row">
                                            <div class="form-group col-md-12">
                                                <label for="">Title</label>
                                                <input type="text" class="form-control" v-model="form.title" placeholder="Insert Title" required/>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="">Image</label><br>
                                                <file-upload
                                                class="btn btn-lg btn-current mr-1"
                                                @input="updateFile"
                                                @input-file="inputFile"
                                                @input-filter="fileFilter"
                                                :custom-action="fileAction"
                                                accept="image/*"
                                                ref="upload" required>
                                                <i class="fa fa-plus"></i>
                                                Upload
                                                </file-upload>

                                                <a v-if="form.image" :href=" form.image" target="_blank" class="btn btn-lg btn-success">Current File</a>

                                                <button type="button" class="btn btn-lg btn-danger ml-1"  v-if="!$refs.upload || $refs.upload.active" @click.prevent="$refs.upload.active = false">
                                                <i class="fa fa-stop" aria-hidden="true"></i>
                                                Stop Upload
                                                </button>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="">Sequence</label>
                                                <input type="number" class="form-control" v-model="form.sequence" placeholder="Insert Sequence" required/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 text-right">
                                        <button type="submit" class="btn btn-current">
                                        Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>
import axios from 'axios'
import FileUpload from 'vue-upload-component'
export default {
    components:{
        FileUpload
    },
    data(){
        return {
            media : process.env.VUE_APP_URL_CLOUD,
            form : {
                title: '',
                sequence: '',
                image: ''
            },
            files: [],
        }
    },
    methods: {
        async postPartner() {
            var data = this.form
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/partner`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Partner',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'SettingsPartnerAdmin' })
            }).catch(err => {
                if (err.response.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'Partner',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        updateFile(value) {
            this.files = value
        },
        async fileAction(file) {
            return this.postFile(file)
        },
        async inputFile(newFile, oldFile) {
            if (!newFile && oldFile) {
                if (oldFile.success && oldFile.response.path) {
                    // this.deleteFile(oldFile)
                }
            }
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs.upload.active) {
                    this.$refs.upload.active = true
                }
            }
        },
        fileFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                if (!/\.(png|jpg|svg|webp)$/i.test(newFile.name)) {
                    return prevent()
                }
            }
        },
        async postFile(file){
            let data = new FormData();
            data.append('dir', 'partners');
            data.append('attachment', file.file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                file.response = res.data
                this.form.image = res.data.path
                return res.data
            })
        },
        async deleteFile(file){
            let data = new FormData();
            data.append('path', file.response.path);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/delete`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.files = []
                this.image = ''
                return res.data
            })
        },
    }
}
</script>